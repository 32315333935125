import SSCard from "../../../Layouts/SuccessStory/SSCard/SSCard";
import "./NMSuccessStory.css";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  SUCCESS_STORIES_API,
  SUCCESS_STORY_TAGS_API,
} from "../../../../Utilities/APIs";
import Tags from "../Tags/Tags";
import SelectTags from "../SelectTags/SelectTags";
import MoreButton from "../MoreButton/MoreButton";

function NMSuccessStory() {
  const [selectedTag, setSelectedTag] = useState("all");
  const [showSuccessStories, setShowSuccessStories] = useState(4);
  const [showMobileSuccessStories, setMobileSuccessStories] = useState(2);

  const [successStoryTags, setSuccessStoryTags] = useState(null);
  const [successStories, setSuccessStories] = useState(null);

  const filterDatas = successStories?.filter(
    (successStory) =>
      selectedTag === "all" || successStory.successStoryTag === selectedTag
  );

  const [showIframe, setShowIframe] = useState(false);
  const [addVLink, setAddVLink] = useState(false);
  const [itemID, setItemID] = useState("");

  useEffect(() => {
    async function getSuccessStoryTags() {
      const { data } = await axios.get(SUCCESS_STORY_TAGS_API);
      setSuccessStoryTags(data);
    }
    getSuccessStoryTags();
  }, []);

  useEffect(() => {
    async function getSuccessStories() {
      const { data } = await axios.get(SUCCESS_STORIES_API);
      setSuccessStories(data);
    }
    getSuccessStories();
  }, []);

  const handleClick = (tagId) => {
    setSelectedTag(tagId);
  };

  const handleLoadMore = () => {
    setShowSuccessStories((prevCount) => prevCount + 4);
  };
  const handleClickForSmall = () => {
    setMobileSuccessStories((prevCount) => prevCount + 2);
  };

  const openIframe = (link, id) => {
    setShowIframe(true);
    setAddVLink(link);
    setItemID(id);
  };
  const closeIframe = () => {
    setShowIframe(false);
    setAddVLink("");
    setItemID("");
  };

  return (
    <div className="nm_success_gallery pb_10 mb_10">
      <div className="container_fluid container">
        <h1>News & Interviews</h1>

        <Tags
          selectedTag={selectedTag}
          handleClick={handleClick}
          allText="All"
          tags={successStoryTags}
        />

        <SelectTags
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
          allText="All"
          tags={successStoryTags}
        />
        <div className="nm_success_story">
          {filterDatas?.slice(0, showSuccessStories)?.map((successStory) => (
            <SSCard
              content={successStory}
              showIframe={showIframe}
              addVLink={addVLink}
              openIframe={openIframe}
              closeIframe={closeIframe}
              itemID={itemID}
            />
          ))}
        </div>
        <div className="nm_success_story_small">
          {filterDatas
            ?.slice(0, showMobileSuccessStories)
            ?.map((successStory) => (
              <SSCard
                content={successStory}
                showIframe={showIframe}
                addVLink={addVLink}
                openIframe={openIframe}
                closeIframe={closeIframe}
                itemID={itemID}
              />
            ))}
        </div>
        {filterDatas?.length > showSuccessStories && (
          <MoreButton handleClick={handleLoadMore} text=" Load More" isLarge />
        )}
        {filterDatas?.length > showMobileSuccessStories && (
          <MoreButton
            handleClickForSmall={handleClickForSmall}
            text=" Load More"
            isSmall
          />
        )}
      </div>
    </div>
  );
}

export default NMSuccessStory;
