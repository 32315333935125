import MediaCard from "../../../Layouts/MediaCard/MediaCard";
import "./NMReports.css";
import report1 from "../../../../Assets/Images/reports/Bangladesh Progress Revised.pdf"
import pdf from "../../../../Assets/Images/reports/BCES_Circularity-Report-2024.pdf"
import image from "../../../../Assets/Images/reports/Post-Event-Report.png"
import image1 from "../../../../Assets/Images/reports/Bangladesh Progress Revised.webp"

function NMReports() {
  const reports = [
    {
      id: "1",
      img: "https://mostafiz.org/static/media/Bangladesh%20Progress%20Revised.fe975fc9be8d4b27210b.webp",
      date: "2024-10-10",
      tag: "Nation Branding",
      name: "Bangladesh Benchmarking Garment Industry Progress",
      link: "https://mostafiz.org/static/media/Bangladesh%20Progress%20Revised.8dd501e1aadafe2b8922.pdf",
    },
    {
      id: "2",
      img: "https://mostafiz.org/static/media/Post-Event-Report.83e83f28766a74e1ca80.png",
      date: "2024-06-11",
      tag: "Circularity",
      name: "Fashion, Circular Economy & Bangladesh",
      link: "https://mostafiz.org/static/media/BCES_Circularity-Report-2024.b48e6dbe669de59c5ba9.pdf",
    },
  ];

  return (
    <div className="reports_gallery">
      <div className="container_fluid container">
        <h1>Reports</h1>

        <div className="nm_reports">
          {reports?.map((report) => (
            <MediaCard
              key={report.id}
              img={report.img}
              date={report?.date}
              tag={report?.tag}
              name={report?.name}
              link={report?.link}
            />
          ))}
        </div>

        <div className="nm_reports_small">
          {reports?.map((report) => (
            <MediaCard
              key={report.id}
              img={report.img}
              date={report?.date}
              tag={report?.tag}
              name={report?.name}
              link={report?.link}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default NMReports;
