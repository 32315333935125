import { useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { ToastContainer } from 'react-toastify';


import AppContext from './AppContext/AppContext';
import Home from './Components/Screens/Home/Home';
import About from './Components/Screens/About/About';
import Service from './Components/Screens/Service/Service';
import Contact from './Components/Screens/Contact/Contact';
import Footer from './Components/Partials/Footer/Footer';
import ScrollTop from './ScrollTop';
import Modal from './Components/Layouts/Modal/Modal';
import Iframe from './Components/Layouts/Iframe/Iframe';
import NewsMedia from './Components/Screens/NewsMedia/NewsMedia';
import Media from './Components/Screens/Media/Media';

function App() {

  const { showModal } = useContext(AppContext)


  return (
    <BrowserRouter>
      <ScrollTop />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/service/:slug' element={<Service />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/articles-and-interview' element={<NewsMedia />} />
        <Route path='/media' element={<Media/>} />
      </Routes>
      <Footer />
      {showModal &&
        <Modal>
          <Iframe />
        </Modal>
      }
         <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
